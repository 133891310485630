<template>
  <div class="mt-6 w-10/12 max-w-2xl">
    <SkillsGraphBar
      v-for="(skill, index) in skills"
      :key="skill + index"
      :skill="skill"
    />
  </div>
</template>

<script>
import SkillsGraphBar from '@/components/SkillsGraphBar.vue'

export default {
  name: 'SkillsGraph',
  components: { SkillsGraphBar },
  data() {
    return {
      skills: [
        { name: 'Vue', total: 9 },
        { name: 'Tailwind', total: 9 },
        { name: 'HTML', total: 9 },
        { name: 'Node', total: 8 },
        { name: 'React', total: 8 },
        { name: 'Express', total: 8 },
        { name: 'JS', total: 8 },
        { name: 'CSS', total: 7 },
        { name: 'Python', total: 7 },
        { name: 'Ruby', total: 5 }
      ]
    }
  }
}
</script>
