<template>
  <div class="w-full h-6 bg-gray-200 dark:bg-gray-700 flex mb-4 rounded-lg">
    <div
      class="h-full w-2/12 bg-lightBlue-500 text-white flex justify-center items-center rounded-l-lg text-xs md:text-sm"
    >
      {{ skill.name }}
    </div>
    <div
      class="h-full bg-lightBlue-700 w-0 rounded-r-lg bar-start"
      :class="{
        'w-9/12': nine,
        'w-8/12': eight,
        'w-7/12': seven,
        'w-5/12': five
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'SkillsGraphBar',
  props: ['skill'],
  data() {
    return {
      nine: false,
      eight: false,
      seven: false,
      five: false
    }
  },

  beforeMount() {
    const number = this.skill.total

    setTimeout(() => {
      switch (number) {
        case 9:
          this.nine = true
          break
        case 8:
          this.eight = true
          break
        case 7:
          this.seven = true
          break
        case 5:
          this.five = true
          break
      }
    }, 100)
  }
}
</script>

<style scoped>
.bar-start {
  transition: all 0.5s ease-out;
}
</style>
