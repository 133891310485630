<template>
  <div class="flex flex-col justify-center items-center pt-24 pb-12 md:pt-12">
    <div class="flex flex-col items-center">
      <h1 class="title">
        About me
      </h1>
      <hr class="bg-lightBlue-500 h-1 border-none mt-1 w-24 md:w-32 lg:w-40" />
      <img
        :src="me"
        alt="picture of me"
        class="w-32 mt-4 rounded-full md:w-40 lg:w-48"
      />
      <p class="text mt-8 w-10/12 max-w-2xl">
        I'm a software developer based in South Texas that is passionate about
        web development. I recently graduated from the
        <a
          href="https://www.utrgv.edu"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500"
        >
          University of Texas Rio Grande Valley
        </a>
        with a Bachelor's degree in Computer Engineering (Fall 2020).
      </p>
      <p class="text mt-4 w-10/12 max-w-2xl">
        The main area of my expertise is front end development. I build small
        and medium web apps using VueJS and ReactJS for frontend and ExpressJS
        for the backend.
      </p>
    </div>
    <div class="flex flex-col items-center">
      <h2 class="sub-title text-center">
        Tech skills
      </h2>
      <p class="text mt-4 w-10/12 max-w-2xl">
        I have experience working with these technologies. They are ranked in
        the way I feel the most comfortable working with, however, I am always
        looking to increase my technological knowledge.
      </p>
      <SkillsGraph />
    </div>
  </div>
</template>

<script>
import SkillsGraph from '@/components/SkillsGraph.vue'

export default {
  name: 'About',
  components: { SkillsGraph },
  data() {
    return {
      me: 'https://i.imgur.com/ddF6u4L.jpg'
    }
  }
}
</script>
